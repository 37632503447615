import jQuery from 'jquery';
import anime from 'animejs';

var anchor_char = '+';

export function getHash(){
  return location.hash ? location.hash.split(anchor_char)[0] : '#chapter_00';
}

// export function getFullHash(){
//   return location.hash ? location.hash : '';
// }

export function getIdFromUrl(){
  return location.hash ? '#' + location.hash.split(anchor_char)[1] : '';
}

export function checkHashExist(hash) {
  // The hash is wrong, redirect to the home
  if(hash !== '' && !$(hash).hasClass('single_chapter') ) {
    location = location.origin + location.pathname; // Redirect to home
    return false;
  } else {
    return true;
  }
}

export function indexToHash (index) {
  var hash = '#chapter_';
  if(index < 10) {
    hash += '0'
  }
  return hash + index
}

export function hashToIndex (hash) {
  return parseInt(hash.replace('#chapter_', ''));
}

export function scrollToItem (item) {
  var current_chapter = getHash();
  if (item !== undefined && $(current_chapter).find(item).length > 0) {
    var top = $(current_chapter).find(item).offset().top - 100;
    anime({
      targets: 'html',
      scrollTop: top,
      duration: 600,
      easing: 'easeInOutQuad'
    });
  } else {
    return
  }
}
