import Flickity from 'flickity'

export function initCarousel() {

  const carousels = document.querySelectorAll('.inline_carousel');
  const flkty_array = []

  carousels.forEach((elem)=>{
    const flkty = new Flickity( elem, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.01,
      friction: 0.15,
      draggable: isDraggable()
    });

    flkty_array.push(flkty)
  })

  const carousels_img = document.querySelectorAll('.image_carousel');
  const flkty_image_array = []

  carousels_img.forEach((elem)=>{
    const flkty_image = new Flickity( elem, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.01,
      friction: 0.15,
      draggable: isDraggable()
    });

    flkty_image_array.push(flkty_image)
  })

  window.addEventListener('hashchange', () =>{
    setTimeout(function(){
      if(flkty_array.length > 0) {
        flkty_array.map(item => {item.isDraggable = isDraggable(); item.resize()})
      }
      if(flkty_image_array.length > 0) {
        flkty_array.map(item => {item.isDraggable = isDraggable(); item.resize()})
      }
    }, 1000)
  })

}

function isDraggable() {
  return window.innerWidth < 1040
}

