// import './js/main.js'
import './styl/application.styl'

import Router from './util/Router';
import common from './routes/common';
import pageTemplateTplPillar from './routes/page-template-tpl-pillar';
import pageTemplateTplServices from './routes/page-template-tpl-services';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  pageTemplateTplPillar,
  pageTemplateTplServices
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
