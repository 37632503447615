import jQuery from 'jquery';
import anime from 'animejs';
import { getHash } from '../js/utils'
var debounce = require('debounce');

export function initSide() {
  console.log('side');

  jQuery(function($) {

    $(document).ready(function() {

      initSide();

      // Side navigation
      $('.side_chapters').mouseenter(function(){
        $(this).addClass('hovered');
        $('body').addClass('move_right');
        // $('.main_content').addClass('move_right');
        // $('.fixed_header').addClass('move_right');
      });

      $('.side_chapters').mouseleave(function(){
        $(this).removeClass('hovered');
        $('body').removeClass('move_right');
        // $('.main_content').removeClass('move_right');
        // $('.fixed_header').removeClass('move_right');

      });
    });


    $(window).on('scroll', function(){
      var hero_h = initSide();
      if($(window).scrollTop() >= hero_h) {
        $('.side_chapters').addClass('fixed');
      } else {
        $('.side_chapters').removeClass('fixed');
      }
    });

    $(window).on('resize', debounce(initSide, 250) );

    function initSide(){
      var active_section = getHash();
      var hero_h = $(active_section).find('.hero_chapter').outerHeight();
      $('.side_chapters').css('top', hero_h);
      return hero_h;
    }

  })
}
