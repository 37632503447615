var close_btn = document.querySelector('.close');
var modal_box = document.querySelector('.modal_box');

export function initPdfForm() {

  setTimeout(function(){
    modal_box.classList.add('active');
  }, 12000)

  close_btn.addEventListener('click', function (event) {
    console.log('clock');
    modal_box.classList.remove('active');
  })
}
