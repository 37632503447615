import lity from 'lity'

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');

    jQuery(function($) {

      $(document).ready(function() {
        
        $('.menu-item-has-children > a').on('click', function(event){
          event.preventDefault()
          $('.sub-menu').toggleClass('open');
          $(this).parent().toggleClass('open-parent');
        })

        // Video popup
        $(document).on('click', '[data-lightbox]', lity);

      })
    })
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
