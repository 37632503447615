import jQuery from 'jquery';
import anime from 'animejs';
var debounce = require('debounce');


export function accordionSetup() {

  jQuery(function($) {

    $(document).ready(function() {
      // Accordion
      accordionInit();

      $('.accordion .header').click(function(event){
        var acc_h
        var accordion = $(this).parent()
        var header_h = $(this).outerHeight()

        if(accordion.hasClass('open')){
          accordion.removeClass('open')
          acc_h = header_h
        } else {
          accordion.addClass('open')
          dataLayer.push({
    				'event': 'accordion_open',
            'page': {
                  'title': $('.accordion .header .title').text()
                }
    			});
          acc_h = accordion.attr('data-h')
        }

        anime({
          targets: accordion[0],
          height: acc_h,
          duration: 500,
          easing: 'easeInOutQuad',
          complete: function(anim) {
            if(accordion.hasClass('open')){
              accordion.attr('style', '');
            }

          }
        });
      })

    });

    $(window).on('resize', debounce(setAccordionHeight, 200) );

  })
}


export function accordionInit(){
  setAccordionHeight();

  jQuery(function($) {
    $('.accordion').each(function(){
      // Wrap accordion
      if( !$(this).prev().hasClass('accordion') ) {
        $(this).addClass('first')
      }
      if( !$(this).next().hasClass('accordion') ) {
        $(this).addClass('last')
      }
      if(!$(this).hasClass('text-block')){
        // close
        var header_h = $(this).find('.header').outerHeight();
        $(this).height(header_h);
        $(this).removeClass('open');
      }
      
    })
  })
}

function setAccordionHeight () {
  jQuery(function($) {
    $('.accordion').each(function(){
      if(!$(this).hasClass('text-block')){
        // Set h
        var header_h = $(this).find('.header').outerHeight();
        var content_h = $(this).find('.content').outerHeight();
        var acc_h = header_h + content_h + 50;
        $(this).attr('data-h', acc_h);
        // Set heigh just if is closed
        if(!$(this).hasClass('open')) $(this).height(header_h);
      }
    })
  })
}
