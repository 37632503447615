import { accordionSetup, accordionInit } from '../js/accordion.js'
import Flickity from 'flickity'

export default {
  init() {
    console.log('services');

    const carousels = document.querySelectorAll('.inline_carousel');
    const flkty_array = []
    carousels.forEach((elem)=>{
      const flkty = new Flickity( elem, {
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        selectedAttraction: 0.01,
        friction: 0.15,
        draggable: isDraggable()
      });
      flkty_array.push(flkty)
    })

    window.addEventListener('resize', () =>{
      setTimeout(function(){
        if(flkty_array.length > 0) {
          flkty_array.map(item => {item.isDraggable = isDraggable(); item.resize()})
        }
      }, 500)
    })

    accordionSetup();
    accordionInit();
  },
  finalize() {
    
  },
};

function isDraggable() {
  return window.innerWidth < 980
}
