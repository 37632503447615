import jQuery from 'jquery'
import anime from 'animejs'
import lity from 'lity'
import '../../node_modules/lity/dist/lity.css'
import { initSide } from '../js/side_nav.js'
import { initCarousel } from '../js/inline_carousel.js'
import { initPdfForm } from '../js/pdf_form.js'
import { accordionSetup, accordionInit } from '../js/accordion.js'
import {
  getHash,
  getIdFromUrl,
  checkHashExist,
  indexToHash,
  hashToIndex,
  scrollToItem,
} from '../js/utils'
let Rellax = require('rellax')
var debounce = require('debounce')
var scrollDisable = false

window.dataLayer = window.dataLayer || []

export default {
  init() {
    console.log('guida-design-sprint')
  },
  finalize() {
    initPdfForm()
    initSide()
    accordionSetup()
    initCarousel()

    // JavaScript to be fired on the pillar page, after the init JS
    jQuery(function ($) {
      $(document).ready(function () {
        var rellax_elem = document.querySelector('.rellax')
        if (rellax_elem) {
          var rellax = new Rellax('.rellax', {
            breakpoints: [576, 768, 1201],
          })
        }

        // Load section by hash
        hashInit()
        // Hide loader
        setTimeout(function () {
          hideLoader()
        }, 2000)
        // Video popup
        $(document).on('click', '[data-lightbox]', lity)

        // Menu open
        $('.menu_btn').click(function () {
          $('body').addClass('noScroll')
          $('.menu-full').show()
          anime({
            targets: '.menu-full',
            opacity: [0, 1],
            scale: [1.1, 1],
            duration: 300,
            easing: 'easeInOutQuad',
          })
        })

        $('.close_menu').click(function () {
          closeMenu()
        })

        function closeMenu() {
          anime({
            targets: '.menu-full',
            opacity: 0,
            scale: [1, 1.1],
            duration: 200,
            easing: 'linear',
            complete: function (anim) {
              $('.menu-full').hide()
              $('body').removeClass('noScroll')
            },
          })
        }

        // Menu close
        $(document).on('keyup', function (e) {
          var tag = e.target.tagName.toLowerCase()
          if (e.keyCode === 27 && $('.menu-full').hasClass('show_menu')) {
            $('.menu-full').removeClass('show_menu')
            $('.menu_btn').removeClass('active')
          }
        })

        // Scroll to beginning chapter
        $('.leggi-capitolo').click(function (event) {
          event.preventDefault()
          var top = $(this).parents('.hero_chapter').outerHeight()
          anime({
            targets: 'html',
            scrollTop: top,
            duration: 600,
            easing: 'easeInOutQuad',
          })
        })

        // Click navigation
        $('.chapter_link').click(function (event) {
          event.preventDefault()
          // Close menu
          closeMenu()
          // $('.menu-full').removeClass('show_menu');
          // $('body').removeClass('noScroll');

          // Change hash
          location.hash = $(this).attr('href')
        })

        $('.site-title').click(function (event) {
          // location.hash = '#chapter_00'
          showLoader()
          location = location.origin + location.pathname
        })
      })

      var lastScrollTop = document.documentElement.scrollTop

      $(window).on('load', function () {
        var id_scroll_to = getIdFromUrl()
        hideLoader()
        scrollToItem(id_scroll_to)
      })

      $(window).on('scroll', debounce(changeHashOnScroll, 120))

      $(window).on(
        'scroll',
        debounce(function () {
          var current_hero_id = getHash() + ' .hero_chapter'
          var current_header_id = getHash() + ' .fixed_header'
          var current_hero_h = $(current_hero_id).outerHeight()

          // Show / hide the small header
          var st = window.pageYOffset || document.documentElement.scrollTop

          if (lastScrollTop >= current_hero_h + 50) {
            if (st >= lastScrollTop) {
              // downscroll code
              $(current_header_id).addClass('hidden')
            } else {
              // upscroll code
              $(current_header_id).removeClass('hidden')
            }
          } else {
            $(current_header_id).addClass('hidden')
          }

          lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling

          // Fill the loader next chapter
          var current_hash = getHash()
          var scrolling_point = $(window).scrollTop() + $(window).height()
          var current_loader = $(current_hash).find('.loader_scroll')
          var percent = 0
          var offset = 0
          if (current_loader.length > 0) {
            var loader_top = current_loader.offset().top
            var loader_h = current_loader.outerHeight()
            if (scrolling_point >= loader_top) {
              if ('ontouchstart' in document.documentElement) {
                // Mobile
                offset = 120
              } else {
                // Desktop
                offset = 0
              }

              percent = 1 - (scrolling_point - loader_top + offset) / loader_h
              percent *= 295.5
              if (percent <= 0) percent = 0

              current_loader.find('.loading').css('stroke-dashoffset', percent)
            }
          }
        }, 15)
      )

      $(window).on('hashchange', function (event) {
        var url_from = event.originalEvent.oldURL
        var url_to = event.originalEvent.newURL
        var hash_from = url_from.split('#')[1]
          ? url_from.split('#')[1].split('+')[0]
          : 'chapter_00'
        var hash_to = url_to.split('#')[1].split('/')[0]
        var element_out = '#' + hash_from
        var element_in = getHash()
        // var element_in_w_anchor = getFullHash();

        if (getHash() === '' || getHash() === '#chapter_00') {
          /// Siamo sulla home -> '.home_intro'
          dataLayer.push({
            event: 'virtualPageView',
            page: {
              title: 'Pillarpage - Intro',
              url: 'pillarpage:intro',
            },
          })
        }

        if (hash_from === undefined && hash_to === 'chapter_00') return

        if (element_in === '#chapter_00') {
          $('body').addClass('chapter_00')
        } else {
          $('body').removeClass('chapter_00')
        }

        if (!checkHashExist(element_in)) return

        // Run the animation
        // $('.fixed_header').removeClass('move_right');
        $('body').removeClass('move_right')

        // It's the same element, just scroll
        if (element_in === element_out) {
          var item = getIdFromUrl()
          scrollToItem(item)
        } else {
          in_out_animation(element_in, element_out)
        }
      })

      function changeHashOnScroll(event) {
        if (scrollDisable) {
          event.preventDefault()
          return
        }
        var offset = 0
        var current_hash = getHash()
        var index_hash = hashToIndex(current_hash)
        var all = $('.single_chapter')
          .map(function () {
            return this.innerHTML
          })
          .get()
        var total_chapters = all.length - 1

        // Change location based on scroll
        var scrolling_point = $(window).scrollTop() + $(window).height()

        // Mobile
        if ('ontouchstart' in document.documentElement) {
          offset = 120
        } else {
          offset = 0
        }

        // Fix for Firefox
        offset += 2

        if (scrolling_point >= $(document).height() - offset) {
          if (index_hash < total_chapters) {
            index_hash++
            location.hash = indexToHash(index_hash)
            window.addEventListener('scroll', noScroll)
          }
        }
        // if($(window).scrollTop() <= 0) {
        //   if(index_hash > 0) {
        //     index_hash--
        //     location.hash = indexToHash(index_hash);
        //   }
        // }
      }

      function in_out_animation(element_in_w_anchor, element_out_w_anchor) {
        scrollDisable = true
        var element_in = element_in_w_anchor.toString().split('/')[0]
        var element_out = element_out_w_anchor.toString().split('/')[0]
        var id_scroll_to = '#' + element_in_w_anchor.toString().split('/')[1]
        var element_in_no_hash = element_in.replace('#', '')
        var wrapper_in =
          document.getElementById(element_in_no_hash).firstElementChild
        var window_height = $(window).outerHeight()

        $('body').addClass('noScroll')

        // The hash is wrong, redirect to the home
        checkHashExist(element_in)
        sideSetup()
        // Recalculate accordion height now the element is visible
        accordionInit()

        var direction = '100vh'
        var duration = 800

        setTimeout(function () {
          $(element_out).fadeOut()
        }, duration)

        // Animation In
        // $('.single_chapter').css('position', 'absolute');
        $('.single_chapter').css('z-index', 1)
        $(element_in).show()
        $(element_in).css('position', 'fixed')
        $(element_in).css('z-index', 10)
        $(element_in).addClass('element_in')
        $(element_out).addClass('element_out')
        $(wrapper_in).css('position', 'absolute')
        var bg_color = $(element_in).attr('data-color')

        anime({
          targets: wrapper_in,
          top: [-window_height, 0],
          duration: duration,
          easing: 'easeInOutQuad',
        })

        anime({
          targets: element_in,
          top: [window_height, 0],
          duration: duration,
          easing: 'easeInOutQuad',
          complete: function (anim) {
            // Scroll top
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

            $(element_in).css('position', 'absolute')
            $(element_in).css('transform', 'none')

            $(element_out).removeClass('element_out')

            $('.side_chapters').css('background', bg_color)
            $(element_in).css('z-index', 5)
            $(wrapper_in).css('position', 'relative')

            // Change hash
            // location.hash = element_in_w_anchor;

            dataLayer.push({
              event: 'virtualPageView',
              page: {
                title:
                  'Pillarpage - Capitolo ' + parseInt(getHash().split('_')[1]),
                url: 'pillarpage:capitolo-' + parseInt(getHash().split('_')[1]),
              },
            })

            $(element_in).removeClass('element_in')
            $('body').removeClass('noScroll')

            // enable global scroll
            scrollDisable = false
            setTimeout(function () {
              window.removeEventListener('scroll', noScroll)
              // scrollToItem(id_scroll_to);
            }, 500)

            var item = getIdFromUrl()
            scrollToItem(item)
          },
        })

        // Side OUT animation
        anime({
          targets: '.side_chapters',
          left: [0, -500],
          duration: 500,
          easing: 'linear',
          complete: function (anim) {
            $('.side_chapters').hide()
            // If is not home
            if (getHash() !== '' && getHash() !== '#chapter_00') {
              sideSetup()
              // Side IN animation
              anime({
                targets: '.side_chapters',
                left: [-500, 0],
                duration: 500,
                easing: 'linear',
              })
            }
          },
        })
      }

      function hashInit() {
        var chapter = getHash()
        checkHashExist(chapter)

        $('.single_chapter').hide()
        $(chapter).show()

        $('.single_chapter').last().addClass('last_chapter')

        // The hash is wrong, redirect to the home
        sideSetup()

        if (chapter !== '' && chapter !== '#chapter_00') {
          $('.home_intro').hide()
          $(chapter).show()
        } else {
          $('.home_intro').show()
          // location.hash = '#chapter_00';
        }

        var chapter = getHash()
        if (chapter === '#chapter_00') {
          $('body').addClass('chapter_00')
        } else {
          $('body').removeClass('chapter_00')
        }

        // Scroll top
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
      }

      // function getHash(){
      //   return location.hash ? location.hash.split('/') : '#chapter_00';
      // }

      function sideSetup() {
        var chapter = getHash()
        var index = hashToIndex(chapter)
        var bg_color = $(chapter).attr('data-color')
        if (chapter !== '#chapter_00') {
          $('.side_chapters').show()
          $('.side_chapters').css('background', bg_color)
          $('.chapter_item').removeClass('active')
          $('.chapter_item')
            .eq(index - 1)
            .addClass('active')
        } else {
          $('.side_chapters').hide()
        }
      }

      function hideLoader() {
        $('#loader').fadeOut()
      }

      function showLoader() {
        $('#loader').fadeIn()
      }

      function noScroll() {
        window.scrollTo(0, 0)
      }
    }) // jQuery conflicts
  },
}
